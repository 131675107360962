import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from "@material-ui/core/Tooltip";
import useUpdateMedicationFilter from '@devsontap/nica-locator-core/core/hooks/useUpdateMedicationFilter';

import './index.css';

export const ALL = [
    { key: process.env.REACT_APP_REGENCOV_ID, groupKey: "ed8a0853", label: "REGEN-COV", or: true },
    { key: process.env.REACT_APP_SOTROVIMAB_ID, groupKey: "ed8a0853", label: "Sotrovimab", or: true },
    { key: process.env.REACT_APP_BAMLANIVIMAB_ID, groupKey: "ed8a0853", label: "Bamlanivimab/Etesevimab", or: true },
];

const REGEN = [ ALL[0] ];
const SOTROVIMAB = [ ALL[1] ];
// const BAM = [ ALL[2] ];
const DISABLED_MESSAGE = "This product is currently unavailable per Federal guidelines.";

const Filters = () => {
    const [medication, setMedication] = useState(JSON.stringify(SOTROVIMAB));
    const updateMedicationFilter = useUpdateMedicationFilter();

    const title = (
        <div className="text-lg">
            {DISABLED_MESSAGE}
        </div>
    );

    return (
        <div className="locator-form-filters-container">
            <div className="oswald colorPrimary marginBottom10">
                Filter by Medication
            </div>
            <div>
                <RadioGroup aria-label="medications" name="medications" value={medication} onChange={e => {
                    setMedication(e.target.value);
                    updateMedicationFilter(JSON.parse(e.target.value));
                }}>
                    <FormControlLabel value={JSON.stringify(ALL)} control={<Radio disabled/>} label="All COVID-19 Antibody Therapy Sites" color="primary" />
                    <FormControlLabel value={JSON.stringify(SOTROVIMAB)} control={<Radio />} label="Sotrovimab" />
                    <Tooltip title={title}>
                        <FormControlLabel value={JSON.stringify(REGEN)} control={<Radio disabled={true} />} label="REGEN-COV" />
                    </Tooltip>
                    {/*<Tooltip title={title}>*/}
                    {/*    <FormControlLabel value={JSON.stringify(BAM)} control={<Radio disabled={true} />} label="Bamlanivimab/Etesevimab" />*/}
                    {/*</Tooltip>*/}
                </RadioGroup>
            </div>
        </div>
    )
};

export default Filters;