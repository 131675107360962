import React, { useEffect } from 'react';
import useUpdateMedicationFilter from "@devsontap/nica-locator-core/core/hooks/useUpdateMedicationFilter";

import regeneron from '../../img/regeneron.png';
import gsk from '../../img/logo-gsk.png';
// import lilly from '../../img/LillyLogo_CMYK_Red_EPS.svg'

import './index.css';
import {ALL} from "../filters";

const Sponsors = ({label}) => {
    const updateMedicationFilter = useUpdateMedicationFilter();

    // KBM - This is here so it is ran immediately on mobile, since the Filer on mobile is behind a modal and thus not persistent
    useEffect(() => {
        updateMedicationFilter(ALL);
    }, [updateMedicationFilter]);

    return (
        <div className="flex sponsors-container">
            <div className="sponsors-label bodyFont">
                {label}
            </div>
            <div className="flex sponsors-logos">
                <div className="sponsor-img-wrapper">
                    <a href="https://www.regeneron.com/casirivimab-imdevimab" target="_blank" rel="noopener noreferrer">
                        <img src={regeneron} alt="Regeneron" className="sponsor-img" />
                    </a>
                </div>
                <div className="sponsor-img-wrapper">
                    <a href="https://www.sotrovimab.com/" target="_blank" rel="noopener noreferrer">
                        <img src={gsk} alt="GSK" className="sponsor-img" />
                    </a>
                </div>
                {/*<div>*/}
                {/*    <a href={"https://www.covid19.lilly.com/bam-ete"} target="_blank" rel="noopener noreferrer">*/}
                {/*        <img src={lilly} alt={"Lilly"} className="sponsor-img" />*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
    )
};

Sponsors.defaultProps = {
    label: "NICA Locator Sponsored by:"
};

export default Sponsors;