import React, { useState } from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';

import FAQs from "../faqs";

import nicaLogo from '../../img/nica-logo.svg';

import './index.css';

const TopNav = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [faqsOpen, setFaqsOpen] = useState(false);

    return (
        <>
            <AppBar color="primary" position="sticky">
                <Toolbar className="flex">
                    {/*<div className="md:hidden -ml-3">*/}
                    {/*    <IconButton onClick={() => setDrawerOpen(true)}>*/}
                    {/*        <MenuIcon />*/}
                    {/*    </IconButton>*/}
                    {/*</div>*/}
                    <div>
                        <a href="https://infusioncenter.org" rel="noopener noreferrer" target="_blank">
                            <img src={nicaLogo} alt="NICA" className="h-8 md:h-10" />
                        </a>
                    </div>
                    <div className="hidden sm:block locator-form-header-title headlineFont ml-8">
                        COVID THERAPEUTIC SITE LOCATOR
                    </div>
                    <div className="flex-1 justify-end flex">
                        <Button variant="contained" color="secondary" onClick={() => setFaqsOpen(true)}>
                            FAQs
                        </Button>
                    </div>
                    <div className="hidden md:block">
                    </div>
                </Toolbar>
                <SwipeableDrawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                    <div className="p-2 w-80">
                        <IconButton onClick={() => setDrawerOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                        <div className="pl-4 text-xl text-primary">
                            Patient Support & Resources
                        </div>
                        <List>
                            <ListItem button onClick={() => {}}>
                                <ListItemText primary="LEQVIO Access & Reimbursement Support" />
                            </ListItem>
                        </List>
                    </div>
                </SwipeableDrawer>
            </AppBar>
            <FAQs open={faqsOpen} onClose={() => setFaqsOpen(false)} />
        </>
    );
};

export default TopNav;