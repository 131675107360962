import React from 'react';

import './index.css';

const WelcomeHeader = () => {
    return (
        <div className="-mb-1 sm:-mb-5">
            <div className="locator-form-header-title headlineFont sm:hidden">
                COVID THERAPEUTIC SITE LOCATOR
            </div>
        </div>
    );
};

export default WelcomeHeader;