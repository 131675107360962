import React from 'react';
import NICALocatorCore from '@devsontap/nica-locator-core';

import Sponsors from './components/sponsors';
import Filters from "./components/filters";
import TopNav from "./components/topNav";
import WelcomeHeader from "./components/welcomeHeader";
import LocatorDeprecatedDialog from "./components/dialogs/warning";

import logo from './img/nica-logo.svg';
import logoWhite from './img/ic-nica-logo-large-knockout.svg';
import loginBackgroundImage from './img/login-bg.jpg';

import './App.css';

const App = () => {
    return (
        <NICALocatorCore
            themeOptions={{
                primaryColor: "#6667ab",
                secondaryColor: "#86a1a9",
                secondaryContrastText: "#fff",
                headlineFontFamily: "Oswald",
                bodyFontFamily: "Lato"
            }}
            images={{
                logo,
                logoWhite,
                loginBackgroundImage
            }}
            components={{
                FiltersComponent: Filters,
                FooterComponent: Sponsors,
                TopNavComponent: TopNav,
                WelcomeHeaderComponent: WelcomeHeader
            }}
            flags={{
                // hideFilters: true,
                hideGoToAdmin: true,
                showCovidVerified: true,
                hideWelcomeDialog: true,
            }}
            strings={{
                welcomeMessage: (
                    <>
                        <div>
                            Infusion sites displayed in this tool have been identified as potential administration sites
                            for COVID-19 monoclonal antibody therapy (CmAb) under Emergency Use Authorization, but their
                            inclusion does not imply current availability of doses or schedule availability.
                        </div>
                        <p>
                            NICA's CmAb Site Locator is a public resource and the sites displayed are not continuously vetted.&nbsp;
                            <span style={{color: "black"}}>
                                 YOU ARE RESPONSIBLE FOR CALLING TO DETERMINE WHETHER A SITE IS ACTIVELY ADMINISTERING THERAPY, HAS INVENTORY, AND APPOINTMENT AVAILABILITY.
                            </span>
                        </p>
                        <p>
                            CmAb sites are responsible for adding and/or claiming their center in the locator and for keeping all profile information up to date.
                            YOU MAY NOTIFY US OF INCORRECT SITE INFORMATION BY CLICKING ON THE "SEE INFO THAT NEEDS AN UPDATE?" button in a location's profile.
                        </p>
                        <p>
                            NICA is a nonprofit organization and does not own or operate any infusion centers. NICA and/or its sponsors are not liable for the accuracy of the information in a site’s profile.
                        </p>
                    </>
                )
            }}>
            <LocatorDeprecatedDialog />
        </NICALocatorCore>
    );
};

export default App;
