import React, { useState } from 'react';
import cx from 'classnames';

import arrowRight from '../../../img/right-arrow.svg';

import './index.css';

const FAQItem = ({ faq: { question, answer }, borderTop, glossaryMap }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className={cx("faq-item py-4 border-solid border-dark-blue border-0 border-b cursor-pointer", { "border-t": borderTop })} onClick={() => setExpanded(!expanded)}>
            <div className="flex">
                <div className="font-oswald text-darkBlue font-medium text-xl flex-1 mr-2">
                    {question}
                </div>
                <div className="flex items-center">
                    <img src={arrowRight} alt="arrow right" className={cx("w-3 sm:w-3 sm:h-6 transform transition-all", {
                        "rotate-90": expanded
                    })} />
                </div>
            </div>
            <div className={cx( "p-4", {"hidden": !expanded})}>
                <div className="font-lato text-base text-darkBlue">
                    {answer}
                </div>
            </div>
        </div>
    )
};

export default FAQItem;