import React, { useEffect } from 'react';
import FullScreenDialog from '@devsontap/nica-locator-core/core/components/_common/dialog/fullScreen';
import Button from "@material-ui/core/Button";

import TopNav from "../topNav";

const LocatorDeprecatedDialog = () => {
    useEffect(() => {
        window.setTimeout(() => {
            goToUrl();
        }, 60 * 1000)
    }, []);

    const goToUrl = () => window.location.href = "https://covid-19-therapeutics-locator-dhhs.hub.arcgis.com/";

    return (
        <FullScreenDialog
            open={true}
            onClose={() => {}}>
            <div className="flex-1 flex flex-col z-[4000]">
                <TopNav/>
                <div className="bg-appPage flex-1">
                    <div className="flex justify-center p-10 text-center">
                        <div className="max-w-2xl text-xl">
                            Due to the federal government’s decision to halt distribution of all infusion COVID therapeutics, NICA
                            has discontinued the COVID-19 Locator and related resources. You are now being re-routed to a third-party
                            resource page provided by the U.S. government. NICA is not responsible for and cannot guarantee the
                            accuracy of third-party websites and resources.
                            <div className="mt-16">
                                <Button color="primary" variant="contained" onClick={goToUrl}>
                                    Got it
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FullScreenDialog>
    )
};

export default LocatorDeprecatedDialog;