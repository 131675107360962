import React from 'react';
import Button from '@material-ui/core/Button';
import NICADialog from '@devsontap/nica-locator-core/core/components/_common/dialog';

import FAQItem from "./item";

import FAQList from "./data";

const FAQs = ({ open, onClose }) => {
    return (
        <NICADialog
            open={open}
            onClose={onClose}
            title="FAQs"
            actions={
                <div className="flex justify-end p-2">
                    <Button variant="text" color="primary" onClick={onClose}>
                        DONE
                    </Button>
                </div>
            }>
            <div>
                {FAQList.map((faq, index) => (
                    <FAQItem faq={faq} key={`faq-${index}`} borderTop={index === 0} />
                ))}
            </div>
        </NICADialog>

    )
};

export default FAQs;